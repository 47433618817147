import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFIcon,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { logo, mobileLogo } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/8GFwb8UTNOQ2rbtqc9kk/locations/Y7GXtSFgqD6z5v9Jpd8q'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView columm justifyCenter alignCenter textCenter w="100%" m="0 auto">
          <CFImage w="80%" src={mobileLogo} alt="Nikko Sushi Logo" mt="10px" />
          <CFView
            center
            w="50%"
            br="30px"
            bc="white"
            bw="4px"
            pv="5px"
            zIndex={99}
            m="10px auto"
          >
            <CFView color="white" xBold mb="2px" h2>
              LANGLEY
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          center
          w="100%"
          zIndex={98}
          maxWidth="1400px"
          bg="rgba(0,0,0,.6)"
        >
          <CFView row justifyBetween alignCenter w="100%" ml="140px">
            <CFImage
              pt="9px"
              pb="5px"
              w="450px"
              src={logo}
              alt="Nikko Sushi Logo"
              zIndex={98}
            />
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
